import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './GNGetHelp.scss';
import ClassNames from 'classnames';
import { MODULES_PERMITTED } from '../../../constants/PrivateRoute';
import { MENU_CONTENTS } from '../../../constants/GetHelp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gnviewSetLastURLVisited } from '../../../actions/GNViewAuthActions';
import { gvauthSelInternalUser, gvauthSelModulesPermitted } from '../../../reducers/GNVAuthReducer';

export const GNGetHelp = () => {
    const dispatch = useDispatch();
    const isInternal = useSelector(gvauthSelInternalUser);
    const modulesPermitted = useSelector(gvauthSelModulesPermitted);
    const [showHelpMenu, setShowHelpMenu] = useState(false);
    const [menuContents, setMenuContents] = useState([]);

    useEffect(() => {
        if (isInternal) {
            setMenuContents([...MENU_CONTENTS.filter(i => i.title !== 'Report an Issue' && i.title !== 'Issue Status' && i.title !== 'Send Feedback')]);
        } else if (modulesPermitted) {
            const filteredMenuContents = [...MENU_CONTENTS];
            if (!modulesPermitted.includes(MODULES_PERMITTED.REPORT_AN_ISSUE)) {
                filteredMenuContents.splice(filteredMenuContents.findIndex(i => i.title === "Report an Issue"), 1);
                filteredMenuContents.splice(filteredMenuContents.findIndex(i => i.title === "Issue Status"), 1);
            }
            if (!modulesPermitted.includes(MODULES_PERMITTED.SUBMIT_FEEDBACK)) {
                filteredMenuContents.splice(filteredMenuContents.findIndex(i => i.title === "Send Feedback"), 1);
            }
            setMenuContents([...filteredMenuContents]);
        } else {
            setMenuContents([...MENU_CONTENTS]);
        }
    }, [modulesPermitted, isInternal, setMenuContents]);

    const renderLinkMenuItem = (item, index) => (
        <Link to={item.url} key={index} onClick={() => onMenuItemClick(item)} className={ClassNames('menu-item-container', { 'horizontal-lines': item.title === "What's New!" })}>
            <FontAwesomeIcon icon={item.icon} />
            <span className='item-title'>{item.title}</span>
        </Link>
    );

    const onMenuItemClick = (item) => {
        if (item.title === 'Report an Issue') {
            dispatch(gnviewSetLastURLVisited(window.location));
        }
        setShowHelpMenu(false);
    };

    return (
        <div className="get-help-btn-container">
            {showHelpMenu && <div className={ClassNames("get-help-menu", "gnview")}>
                {menuContents.map((item, index) => {
                    if (!item?.newTab) {
                        return renderLinkMenuItem(item, index);
                    } else {
                        return (
                            <a href={item.url} target="_blank" rel="noreferrer" onClick={() => onMenuItemClick(item)} className={ClassNames('menu-item-container')} key={item.path}>
                                <FontAwesomeIcon icon={item.icon} />
                                <span className='item-title'>{item.title}</span>
                            </a>
                        );
                    }
                })}
            </div>}
            <div className="button-container">
                <Button className={ClassNames("get-help-button", "gnview", { 'menu-open': showHelpMenu })}
                    onClick={() => setShowHelpMenu(!showHelpMenu)}>
                    <FontAwesomeIcon icon='circle-question' /> Get Help
                </Button>
            </div>
        </div>
    )
};

export default GNGetHelp;