
import {
    GNVIEW_PROGRAM_DETAILS_IN_PROGRESS,
    GNVIEW_PROGRAM_DETAILS_SUCCESS,
    GNVIEW_PROGRAM_DETAILS_FAIL,
    GNVIEW_PROGRAM_DETAILS_CLEAR,
    GNVIEW_ADD_RECENT_PROGRAM,
    GNVIEW_PROGRAM_AVAIL_ADD_PROGRAM_IDS,
    GNVIEW_PROGRAM_AVAIL_ADD_QUERY_OBJ,
    GNVIEW_PROGRAM_AVAIL_ADD_STREAMING_CATALOG_IDS,
    GNVIEW_CATALOGS_IN_PROGRESS,
    GNVIEW_CATALOGS_SUCCESS,
    GNVIEW_CATALOGS_FAIL,
    GNVIEW_CATALOGS_CLEAR,
    GNVIEW_PROGRAM_AIRINGS_STORE_SEARCH,
    GNVIEW_PROGRAM_AIRINGS_CLEAR_SEARCH,
    GNVIEW_PROGRAM_AIRINGS_ADD_PROGRAM,
    GNVIEW_ADD_RECENT_STATION,
    GNVIEW_ADD_SELECTED_STATION,
    GNVIEW_CLEAR_SELECTED_STATION,
    GNVIEW_ADVANCED_SEARCH_UPDATE_SEARCH_BY,
    GNVIEW_ADVANCED_SEARCH_PROGRAM_ATTRIBUTES_FIELDS_UPDATE,
    GNVIEW_ADVANCED_SEARCH_FIELDS_CLEAR,
    GNVIEW_ADVANCED_SEARCH_RESULT_TABLE_RESET,
    GNVIEW_ADVANCED_SEARCH_ROOT_ID_FIELDS_UPDATE,
    GNVIEW_ADVANCED_SEARCH_SERIES_ID_FIELDS_UPDATE
} from './ActionTypes';
import { API_ENDPOINTS } from '../config/Api';
import apiService from '../services/ApiService';
import { convertLanguageFromCode } from '../utils/GeneralUtils';
import { SEARCH_CHANNELS_CALL_SIGN } from '../constants/Station';
import { getUniqueSeasons, formatSeasons } from '../utils/SeasonUtils';
import isEmpty from 'lodash.isempty';
import moment from 'moment-timezone';
import { gnviewSendLogMessage } from '../services/GeneralService';
import { ADVANCED_SEARCH_FILTER_ALL_TYPES } from '../constants/GNViewActions';
import { TECHNICAL_PLATFORM_ISSUE } from '../constants/ReportAnIssue';

/** Market Details */
export const gnviewSearchMarkets = (searchString, rankStart, rankEnd) => {
    return () => {
        const body = {
            ...(searchString && {search_string: searchString}),
            ...(rankStart && {rank_start: rankStart}),
            ...(rankEnd && {rank_end: rankEnd})
        };
        return apiService.makeApiCall(API_ENDPOINTS.SEARCH_MARKETS, body, 'get');
    }
};

export const gnviewGetMarket = (marketId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_MARKET}/${marketId}`, {}, 'get');
    }
};

/** Program Details */
export const gnviewSearchPrograms = (searchString, languages, progTypes = []) => {
    return () => {
        const body = {
            search_string: searchString,
            languages,
            program_types: progTypes
        };
        return apiService.makeApiCall(API_ENDPOINTS.SEARCH_PROGRAMS, body, 'get');
    }
};

export const gnviewGetProgramDetailsInProgress = () => ({
    type: GNVIEW_PROGRAM_DETAILS_IN_PROGRESS
});

export const gnviewGetProgramDetailsSuccess = (details) => ({
    type: GNVIEW_PROGRAM_DETAILS_SUCCESS,
    payload: { details }
});

export const gnviewGetProgramDetailsFail = (details) => ({
    type: GNVIEW_PROGRAM_DETAILS_FAIL,
    payload: { details }
});

export const gnviewClearProgramDetails = () => ({
    type: GNVIEW_PROGRAM_DETAILS_CLEAR
});

export const gnviewProgramDetailsAddRecentProgram = (programId) => ({
    type: GNVIEW_ADD_RECENT_PROGRAM,
    payload: { programId }
});


// shouldGetPopularity uses gnviewGetProgramById() to add popularity score. It should always be false, unless you are calling this from program details page
// shouldStore is a flag for saving in the redux store. It should always be false, unless you are calling this from program details page
// shouldGetEpisodes is a flag for calling /episodes for a program, can optionally add this flag if you need to retrieve episodes at the same time
export const gnviewGetProgramDetails = (tmsId, shouldGetPopularity = false, shouldStore = false, shouldGetEpisodes = false) => {
    return (dispatch) => {
        if (shouldStore) {
            dispatch(gnviewGetProgramDetailsInProgress());
        }
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_DETAILS}/${tmsId}`, {}, 'get').then(
            async (response) => {
                if (response.result?.original_audio_language) {
                    response.result.original_audio_language_converted = convertLanguageFromCode(response.result.original_audio_language);
                }
                if (shouldGetPopularity) {
                    const programInfo = await gnviewGetProgramById(tmsId)();
                    response.result.popularity_score = programInfo?.result?.[0]?.popularity_score;
                }
                if (shouldGetEpisodes) {
                    const episodesResponse = await gnviewGetProgramSeasonsAndEpisodes(tmsId)();
                    response.result.allEpisodes = episodesResponse.result;
                    response.result.allSeasons = formatSeasons(getUniqueSeasons(episodesResponse.result, true));
                }
                response.result.entitled = true;
                if (shouldStore) {
                    dispatch(gnviewGetProgramDetailsSuccess(response.result));
                }
                return response;
            },
            (error) => {
                if (error?.response?.status === 403) {
                    error.response.data.entitled = false;
                    error.response.data.hasError = false;
                    // TODO: create a separate function for unauthorized cases
                    if (shouldStore) {
                        dispatch(gnviewGetProgramDetailsFail(error.response.data));
                    }
                } else if (error?.response?.status === 404) {
                    error.response.data.entitled = true;
                    error.response.data.hasError = true;
                    if (shouldStore) {
                        dispatch(gnviewGetProgramDetailsFail(error.response.data));
                    }
                } else if (shouldStore) {
                    dispatch(gnviewGetProgramDetailsFail({entitled: true, hasError: true}));
                }
                throw error;
            });
    }
};

export const gnviewGetProgramDetailsMultiple = (tmsIds, totalCount) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const epRequests =
            tmsIds
                .map((id) => dispatch(gnviewGetProgramDetails(id, false, false, false))
                    .catch((error) => {
                        dispatch(gnviewSendLogMessage(`gnviewGetProgramDetails - episode request fail ${error.message}`, error, id));
                        return error?.response?.data ? {result: error.response.data} : null;
                    }));
            Promise.all(epRequests).then((epResponse) => {
                const episodes = epResponse.filter(ep => (!isEmpty(ep) && !ep.result.hasError)).map((ep) => ep.result);
                resolve({ result: episodes, ...(totalCount && { headers: { total_count: totalCount } }) })
            }).catch((err) => reject(err));
        });
    }
};

export const gnviewGetProgramDetailsUnrestricted = (tmsId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_DETAILS_UNRESTRICTED}/${tmsId}`, {}, 'get').then(
            (response) => {
                if (response.result?.original_audio_language) {
                    response.result.original_audio_language_converted = convertLanguageFromCode(response.result.original_audio_language);
                }
                return response;
            },
            (error) => {
                throw error;
            });
    }
};

export const gnviewGetProgramById = (tmsIds) => {
    const tmsids = Array.isArray(tmsIds) ? tmsIds : [tmsIds]
    return () => {
        const body = { tmsids }
        return apiService.makeApiCall(`${API_ENDPOINTS.SEARCH_PROGRAM_INFO}`, body, 'get');
    }
};

export const gnviewGetProgramSeasonsAndEpisodes = (seriesId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_DETAILS}/${seriesId}/episode-mappings`, {}, 'get');
    }
};

export const gnviewGetProgramOtherVersions = rootId => {
    return () => {
        const body = { root_id: rootId };
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_DETAILS}`, body, 'get');
    }
};

/** Program Airings */
export const gnviewGetProgramAirings = (body) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_AIRINGS}`, body, 'get', true);
    }
};

export const gnviewGetProgramAiringsLastUpdated = () => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_AIRINGS_LAST_UPDATED}`, {}, 'get');
    };
};

export const gnviewProgramAiringsStoreSearch = (queryObj) => ({
    type: GNVIEW_PROGRAM_AIRINGS_STORE_SEARCH,
    payload: { queryObj }
});

export const gnviewProgramAiringsClearSearch = () => ({
    type: GNVIEW_PROGRAM_AIRINGS_CLEAR_SEARCH
});

export const gnviewProgramAiringsAddProgram = (programId) => ({
    type: GNVIEW_PROGRAM_AIRINGS_ADD_PROGRAM,
    payload: { programId }
});

/** Station Details */
export const gnviewSearchStations = (searchString, isCallSignSearch = false, countryFilter) => {
    return () => {
        const body = {
            search_string: searchString,
            ...isCallSignSearch && { type: SEARCH_CHANNELS_CALL_SIGN },
            countries: countryFilter
        };
        return apiService.makeApiCall(API_ENDPOINTS.SEARCH_STATIONS, body, 'get');
    }
};

export const gnviewGetStation = (stationId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_STATION}/${stationId}`, {}, 'get').then(
            (response) => {
                const { ed_languages: edLangs, broadcast_languages: broadcastLangs, address } = response?.result;
                if (edLangs?.length > 0) {
                    response.result.edLanguagesConverted = edLangs.map(lang => convertLanguageFromCode(lang));
                }
                if (broadcastLangs?.length > 0) {
                    response.result.broadcastLanguagesConverted = broadcastLangs.map(lang => convertLanguageFromCode(lang));
                }
                if (address) {
                    response.result.location = `${address.city ? address.city + ', ' : ''}${address.state ? address.state + ', ' : ''}${address.postal_code ? address.postal_code + ', ' : ''}${address.country || ''}`;
                }
                return response;
            },
            (error) => {
                throw error;
            });
    }
};

export const gnviewStationDetailsAddRecentStation = (stationId) => ({
    type: GNVIEW_ADD_RECENT_STATION,
    payload: { stationId }
});

export const gnviewAddSelectedStation = (selectedStation) => ({
    type: GNVIEW_ADD_SELECTED_STATION,
    payload: { selectedStation }
});

export const gnviewClearSelectedStation = () => ({
    type: GNVIEW_CLEAR_SELECTED_STATION
});

/** Saved Searches */
export const gnviewGetAllSavedSearches = () => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_SAVED_SEARCHES}`, {}, 'get');
    }
};

export const gnviewGetSavedSearch = (searchId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_SAVED_SEARCHES}/${searchId}`, {}, 'get', true);
    }
};

export const gnviewDeleteSavedSearch = (savedSearchId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_SAVED_SEARCHES}/${savedSearchId}`, {}, 'delete');
    }
};

export const gnviewCreateSavedSearch = (body) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_SAVED_SEARCHES}`, body, 'post');
    }
};

export const gnviewUpdateSavedSearch = (searchId, body) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_SAVED_SEARCHES}/${searchId}`, body, 'put');
    }
}

/** Person */
export const gnviewGetPersonDetailsById = (personId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PERSON_DETAILS}/${personId}`, {}, 'get');
    }
};

/** Schedules */
export const gnviewGetSchedule = (stationIds, startDate, endDate) => {
    return () => {
        const body = {
            station_ids: stationIds,
            start_date_time: startDate,
            end_date_time: endDate
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_SCHEDULE}`, body, 'get', true);
    }
};

/** Countries */
export const gnviewGetUserStationCountries = () => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SEARCH_STATION_COUNTRIES}`, {}, 'get');
    }
};

/** Export Schedule */
export const gnviewGetExportSchedule = (stationIds, startDate, endDate, defaultTimezone) => {
    return () => {
        const body = {
            station_ids: stationIds,
            start_date_time: startDate,
            end_date_time: endDate,
            default_time_zone: defaultTimezone
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.EXPORT_SCHEDULES}`, body, 'get', true);
    }
};

/** Export Program Season Episodes */
export const gnviewGetExportEpisodes = (seriesId) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.EXPORT_SEASON_EPISODES}/${seriesId}/episodes`, {}, 'get');
    }
};

/** Export Program Airings */
export const gnviewGetExportProgramAirings = (startDate, endDate, parentTmsIds, tmsIds, stationIds, marketIds, countryCodes, stationTypes, displayedTimezone) => {
    return () => {
        const body = {
            start_date_time: startDate,
            end_date_time: endDate,
            parent_tms_ids: parentTmsIds,
            tms_ids: tmsIds,
            station_ids: stationIds,
            market_ids: marketIds,
            country_codes: countryCodes,
            station_types: stationTypes,
            default_time_zone: displayedTimezone
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.EXPORT_PROGRAM_AIRINGS}`, body, 'get');
    }
}

/** Export Program Availabilities */
export const gnviewGetExportProgramAvailabilities = (tmsIds, catalogIds) => {
    const body = {
        tms_ids: tmsIds,
        catalog_ids: catalogIds
    };
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.EXPORT_PROGRAM_AVAILABILITIES}`, body, 'post');
    }
};

/** Streaming Video Catalogs */
export const gnviewGetEntitledCatalogsInProgress = () => ({
    type: GNVIEW_CATALOGS_IN_PROGRESS
});

export const gnviewGetEntitledCatalogsSuccess = (catalogs, shouldStore) => ({
    type: GNVIEW_CATALOGS_SUCCESS,
    payload: { catalogs, shouldStore }
});

export const gnviewGetEntitledCatalogsFail = (catalogs) => ({
    type: GNVIEW_CATALOGS_FAIL,
    payload: { catalogs }
});

export const gnviewClearEntitledCatalogs = () => ({
    type: GNVIEW_CATALOGS_CLEAR
});

export const gnviewGetStreamingVideoCatalogs = (shouldStore = false) => {
    return (dispatch) => {
        dispatch(gnviewGetEntitledCatalogsInProgress());
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_STREAMING_VIDEO_CATALOGS}`, {}, 'get').then(
            (response) => {
                dispatch(gnviewGetEntitledCatalogsSuccess(response.result, shouldStore));
                return response;
            }, (error) => {
                const data = error?.response?.data || [];
                dispatch(gnviewGetEntitledCatalogsFail(data));
                throw error;
            }
        )
    }
};

/** Program Availabilities */
export const gnviewGetProgramAvailabilities = (body) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_AVAILABILITIES}`, body, 'get', true);
    };
};

export const gnviewGetProgramAvailabilitiesLastUpdated = () => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_AVAILABILITIES_LAST_UPDATED}`, {}, 'get');
    };
};

export const gnviewProgramAvailAddProgramIds = (programIds) => ({
    type: GNVIEW_PROGRAM_AVAIL_ADD_PROGRAM_IDS,
    payload: { programIds }
});

export const gnviewProgramAvailAddCatalogIds = (catalogIds) => ({
    type: GNVIEW_PROGRAM_AVAIL_ADD_STREAMING_CATALOG_IDS,
    payload: { catalogIds }
});

export const gnviewProgramAvailAddQueryObj = (queryObj) => ({
    type: GNVIEW_PROGRAM_AVAIL_ADD_QUERY_OBJ,
    payload: { queryObj }
});

/** Video Descriptors */
export const gnviewGetProgramAnnotations = (tmsids) => {
    return () => {
        const body = { tms_ids: tmsids };
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_PROGRAM_ANNOTATIONS}`, body, 'get');
    }
};

export const gnviewGetProgramXML = (tmsid) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.RAW_PROGRAMS_XML}/${tmsid}`, {}, 'get');
    }
};

/** Advanced Search **/
export const gnviewAdvancedSearchProgramIdentifiers = (from, size, params, sortField, sortOrder) => {
    return () => {
        const all = Object.keys(ADVANCED_SEARCH_FILTER_ALL_TYPES).map(type => params[type] === false ? ADVANCED_SEARCH_FILTER_ALL_TYPES[type] : null).filter(filter => filter);
        const genres = params.sportsType ? [...params.genres, params.sportsType] : params.genres;
        const body = {
            from,
            size,
            ...(params?.rootId && { root_ids: [params.rootId] }),
            ...(params?.seriesId && { series_ids: [params.seriesId] }),
            ...(params?.title && { search_string: params.title, search_type: params?.searchType || 'fuzzy' }),
            ...(params?.programTypes && { program_types: params.programTypes }),
            ...(params?.programSubtypes && { program_subtypes: params.programSubtypes }),
            ...(genres?.length > 0 && { genres }),
            ...(params?.productionCompanies?.length > 0 && { production_companies: params.productionCompanies }),
            ...(params?.titleLanguage && { title_languages: params.titleLanguage }),
            ...(params?.audioLanguage && { audio_languages: params.audioLanguage }),
            ...(params?.descriptionLanguage && { description_languages: params.descriptionLanguage }),
            ...(params?.releaseYear && { release_year: params.releaseYear }),
            ...(params?.originalNetworkId && { original_station_ids: params.originalNetworkId }),
            ...(params?.mostRecentNetworkId && { last_original_station_ids: params.mostRecentNetworkId }),
            ...(sortField && { sort_field: sortField }),
            ...(sortOrder && { sort_order: sortOrder }),
            ...(all?.length > 0 && { all })
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.ADVANCED_SEARCH_PROGRAMS}`, body, 'get');
    }
};

export const gnviewGetProgramFieldValues = (field) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SEARCH_PROGRAM_FIELD_VALUES}?fields[]=${field}`, {}, 'get');
    };
};

export const gnviewGetSportProgramFieldValues = (field, programTypes) => {
    const params = {
        fields: field,
        program_types: programTypes
    };

    return () => {
        return apiService.makeApiCall(API_ENDPOINTS.SEARCH_PROGRAM_FIELD_VALUES, params, 'get');
    };
};

export const gnviewSearchProductionCompanyIdentifiers = (searchString) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.SEARCH_PRODUCTION_COMPANY_IDENTIFIERS}?search_string=${searchString}`, {}, 'get');
    }
};

export const gnviewAdvancedSearchUpdateSearchBy = (searchType) => ({
    type: GNVIEW_ADVANCED_SEARCH_UPDATE_SEARCH_BY,
    payload: { searchType }
});

export const gnviewAdvancedSearchRootIdFieldsUpdate = (rootIdFields) => ({
    type: GNVIEW_ADVANCED_SEARCH_ROOT_ID_FIELDS_UPDATE,
    payload: { rootIdFields }
});

export const gnviewAdvancedSearchSeriesIdFieldsUpdate = (seriesIdFields) => ({
    type: GNVIEW_ADVANCED_SEARCH_SERIES_ID_FIELDS_UPDATE,
    payload: { seriesIdFields }
});

export const gnviewAdvancedSearchFieldsUpdate = (programAttributesFields) => ({
    type: GNVIEW_ADVANCED_SEARCH_PROGRAM_ATTRIBUTES_FIELDS_UPDATE,
    payload: { programAttributesFields }
});

export const gnviewAdvancedSearchFieldsClear = () => ({
    type: GNVIEW_ADVANCED_SEARCH_FIELDS_CLEAR
});

export const gnviewAdvancedSearchUpdateResultTableReset = (shouldReset) => ({
    type: GNVIEW_ADVANCED_SEARCH_RESULT_TABLE_RESET,
    payload: { shouldReset }
});

/** Report an Issue **/
export const gnviewReportAnIssue = (reportFields, group, searchCriteria) => {
    const subj = (reportFields.feedbackAbout === TECHNICAL_PLATFORM_ISSUE) ?
        `Tech Issue | ${group}` :
        `Data Discrepancy | ${group}`;


    let desc = reportFields.description;
    if (Object.keys(searchCriteria).length) {
        desc += `Search Criteria: ${JSON.stringify(searchCriteria)}`;
    }
    // localhost url was being rejected on the backend
    const url = reportFields?.url.replace(/localhost:\d\d\d\d/g, "local.gnview.com");
    return () => {
        const body = {
            "Conflict_Date__c": moment(reportFields.conflictDate).format('YYYY-MM-DD'),
            "Description": desc.substring(0, 31999),
            "Discrepancy_Category__c": reportFields?.feedbackAbout,
            "Origin": "GN View Web-to-Case",
            "Priority": "Normal",
            "Product_RNF__c": "Gracenote View",
            "Public_Issue__c": false,
            "Status": "New",
            "Subject": subj.substring(0, 254),
            "SuppliedEmail": reportFields?.userEmail.substring(0, 254),
            "Type": "Discrepancy",
            "URL__c": url.substring(0, 254),
            "Vertical__c": "Video"
        };
        if (reportFields.stationId) {
            body.ProgservID__c = reportFields.stationId;
        }
        if (reportFields.tmsId) {
            body.TMSid__c = reportFields.tmsId;
        }
        return apiService.makeApiCall(`${API_ENDPOINTS.REPORT_AN_ISSUE}`, body, 'post');
    }
};

export const gnviewUploadAttachment = (id, file) => {
    return () => {
        const body = {
            fileName: file.name,
            fileData: file.data,
            caseId: id
        };
        return apiService.makeApiCall(`${API_ENDPOINTS.UPLOAD_ATTACHMENT}`, body, 'post');
    };
};

export const gnviewGetUserCases = (userEmail, sortField, sortOrder) => {
    return () => {
        return apiService.makeApiCall(`${API_ENDPOINTS.GET_CASES}?email=${userEmail}&sort_field=${sortField}&sort_order=${sortOrder}`, {}, 'get');
    }
};