import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import {
    gnviewGetExportSchedule,
    gnviewGetSavedSearch,
    gnviewGetStation,
    gnviewGetSchedule
} from '../../../actions/GNViewActions';
import { gnviewLookupCountUpdate, gnviewPostLookup, gnviewSetLastURLVisited } from '../../../actions/GNViewAuthActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import queryString from 'query-string';
import './ScheduleResult.scss';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ROUTES } from '../../../config/Routes';
import { downloadExportFile, formatExportFileName } from '../../../utils/GeneralUtils';
import { getFormattedDate, getNextDate, getPrevDate } from '../../../utils/DateUtils';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import ExportButton from '../../../components/common/exportButton/ExportButton';
import ScheduleCalendar from './ScheduleCalendar';
import {
    DAILY_MAX_STATIONS,
    EXACT_DATE,
    SCHEDULE_REPORT,
    SCHEDULE_UPDATES_REAL_TIME,
    SCHEDULE_LAST_UPDATED
} from '../../../constants/Schedule';
import StationDropdown from './StationDropdown';
import StationHeader from './StationHeader';
import ScheduleTimeAxis from './ScheduleTimeAxis';
import ClassNames from 'classnames';
import ScheduleLegend from './ScheduleLegend';
import isEmpty from 'lodash.isempty';
import ReactRouterPropTypes from 'react-router-prop-types';
import { gnviewSelUserSettings, gvauthSelEmail, gvauthSelLookupCount, gvauthSelLookupMax, gvauthIsBasic, gvauthHasChannelAlerts } from '../../../reducers/GNVAuthReducer';
import { BROADCAST_DAY_START } from '../../../constants/AccountSettings';
import MySavedItemsButton from '../../../components/common/saveButton/MySavedItemsButton';
import { scheduleSavedItemBodyFrom } from '../../../constants/MySavedItems';
import { getGridStartHour } from '../../../utils/ScheduleUtils';
import GNToggleButton from '../../../components/common/gnToggleButton/GNToggleButton';
import SubscribeModal from '../../details/stations/Subscribe/SubscribeModal';
import { SUBSCRIPTIONS_LIMIT, SUBSCRIPTIONS_LIMIT_TOOLTIP, SUBSCRIPTIONS_TURN_ON_TOOLTIP, UNSUBSCRIBE_MODAL, SUBSCRIBE_TO_ALERTS_LABELS } from '../../../constants/Station';
import GNConfirmationModal from '../../../components/common/gnConfirmationModal/GNConfirmationModal';
import GNNotification from '../../../components/common/gnNotification/GNNotification';
import { gnviewGetUserSubscriptions, gnviewDataAlertsSetSubscriptions } from '../../../actions/DataAlertsActions';
import { deleteSubscription } from '../../../actions/SubscriptionActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertsSummaryButton } from './AlertsSummaryButton';
import { gnviewGetAlertsCount } from '../../../actions/SubscriptionActions';
import { gnviewDataAlertsSetAlertsCounts } from '../../../actions/DataAlertsActions';

export class ScheduleResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alreadySubscribed: false,
            alerts: {},
            isAlertsLoading: false,
            currentDailyStartIndex: 0,
            currentEndDate: null,
            currentStartDate: null,
            // Because of async load of stations
            dailyLastUpdatedTime: {},
            exportIsLoading: false,
            gridStartHour: getGridStartHour(this.props.defaultGridStart),
            headerDates: [],
            isWeekly: true,
            isLoading: true,
            lastUpdatedTime: [],
            minDate: moment().subtract('6', 'months'),
            maxDate: moment().add('6', 'months'),
            nextIsDisabled: false,
            prevIsDisabled: false,
            selectedSearchTitle: null,
            selectedStation: null,
            showSubscribeModal: false,
            showConfirmUnsubscribeModal: false,
            stations: [],
            subscriptions: [],
            subscriptionsLoading: true,
            currentSubscription: {},
            showNotification: false,
            notificationMessage: '',
            isUnsubscriptionInProgress: false
        };
    }

    /*
        @param {object{}} prevAlerts - An object with alert names as keys and value as its count
        @param {string[]} alerts - Array of alert names.
        It updates alerts state variable {Object{}} - Object containing the string name and its count.
    */
    setAlerts(prevAlerts, alerts) {
        alerts.forEach(alert => {
            prevAlerts[alert] = prevAlerts[alert] ? prevAlerts[alert] + 1 : 1
        })
        this.setState({alerts: prevAlerts})
    }

    getHeaderDates = (start, end) => {
        if (start && end) {
            const headerDates = [];
            let diffDate = Math.ceil(end.diff(start, 'days', true));
            const startDate = start.clone();
            while (diffDate >= 0) {
                headerDates.push({ dayName: startDate.format('ddd'), dayOfMonth: startDate.format('D') });
                startDate.add(1, 'days');
                diffDate--;
            }
            return headerDates;
        }
        return [];
    }

    getLastUpdatedTime(stations, isReset = false, isWeekly) {
        if (isReset) {
            this.setState({ lastUpdatedTime: [], dailyLastUpdatedTime: {} });
        }

        stations.forEach((station) => {
            this.props
                .gnviewGetSchedule(station.id, station.utcStartDate, station.utcEndDate)
                .then((response) => {
                    if (response?.result?.length > 0 && station.show) {
                        // eslint-disable-next-line babel/camelcase
                        const { last_updated_time } = response.result[0];
                        const params = queryString.parse(this.props.location.search);
                        const startDate = moment(params.start_date);
                        const endDate = moment(params.end_date);
                        const lastUpdatedTime = last_updated_time
                            .map((daily) => {
                                // eslint-disable-next-line babel/camelcase
                                const { date, update_date } = daily;
                                const currentTimezone = moment.tz.guess(true);
                                const updatedDateTime = moment(update_date).tz(currentTimezone);
                                if (startDate <= moment(date) && moment(date) <= endDate) {
                                    return `${updatedDateTime.format(
                                        'MM/DD/YYYY h:mmA'
                                    )} (${updatedDateTime.zoneAbbr()})`;
                                } else {
                                    return '';
                                }
                            })
                            .filter((updatedTime) => updatedTime?.length > 0);
                        if (isWeekly) {
                            this.setState({ lastUpdatedTime });
                        } else {
                            this.setState({
                                dailyLastUpdatedTime: {
                                    ...this.state.dailyLastUpdatedTime,
                                    [station.id]: lastUpdatedTime.length > 0 ? lastUpdatedTime[0] : ''
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.props.gnviewSendLogMessage(`Schedule getScheduleData error: ${error}`);
                });
        });
    }

    postLookup(email) {
        this.props.gnviewPostLookup({ email })
            .then((response) => {
                this.props.gnviewLookupCountUpdate(response.result);
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`Schedule postLookup error: ${error}`);
            });
    }

    componentDidMount() {
        if (this.props.isBasic) {
            if (!this.props.lookupCount || this.props.lookupCount <= this.props.lookupMax) {
                this.postLookup(this.props.email);
            }
        }
        const params = queryString.parse(this.props.location.search);
        if ((!this.props.isBasic) || (this.props.isBasic && this.props.lookupCount <= this.props.lookupMax)) {
            if (params.station_ids && params.start_date && params.end_date) {
                if (params.station_ids?.length > 0) {
                    const stationIdList = Array.isArray(params.station_ids) ? params.station_ids : [params.station_ids];
                    const stationRequests = stationIdList.map((id) => this.props.gnviewGetStation(id).catch(() => null)) || [];
                    const diffDate = Math.ceil(moment(params.end_date).diff(moment(params.start_date), 'days', true));
                    const isWeekly = diffDate > 1;
                    Promise.all(stationRequests).then((stationData) => {
                        const allStationsAreUnentitled = stationData.every(station => station === null);
                        // If at least 1 valid station, show the results
                        if (!allStationsAreUnentitled) {
                            const stations = stationData.filter(station => !isEmpty(station)).map((station, idx) => {
                                // Converting to user settings default timezone (if exists), and using station timezone if not
                                const timezone = this.props.defaultTimezone || station.result.timezone;
                                // Adjustments to get 12am -12am for a Schedule day versus 6am-6am for a Broadcast day
                                const tzStartDate = this.state.gridStartHour === 0 ? moment.tz(params.start_date, timezone) : moment.tz(params.start_date, timezone).set({ 'hour': BROADCAST_DAY_START, 'minute': 0 });
                                const tzEndDate = this.state.gridStartHour === 0 ? moment.tz(params.end_date, timezone).add(1, 'day') : moment.tz(params.end_date, timezone).add(1, 'day').set({ 'hour': BROADCAST_DAY_START, 'minute': 0 });

                                // Convert date to UTC
                                const utcStartDate = tzStartDate.toISOString();
                                const utcEndDate = tzEndDate.toISOString();

                                return {
                                    ...station.result,
                                    // First one should be shown in weekly, otherwise the first 7 should be shown
                                    show: isWeekly ? (idx === 0) : (idx < DAILY_MAX_STATIONS),
                                    tzStartDate,
                                    tzEndDate,
                                    utcStartDate,
                                    utcEndDate,
                                    events: [],
                                    timezone,
                                    stationOriginalTimezone: station.result.timezone
                                }
                            });
                            const startDate = moment(params.start_date);
                            const endDate = moment(params.end_date);
                            const headerDates = this.getHeaderDates(startDate, endDate);
                            this.getLastUpdatedTime(stations, false, isWeekly);
                            const selectedStation = stations.find(s => s.show);
                            this.setState({ stations, selectedStation, currentStartDate: startDate, currentEndDate: endDate, isWeekly, isLoading: false, headerDates });
                        } else {
                            // Push to Access Denied page if 403 - we hit this block when ALL stations are invalid
                            // TODO: we need to add a message when invalid stations are returned with valid stations
                            this.props.history.push(ROUTES.CONTACT_US);
                        }
                    })
                        .catch((error) => {
                            this.props.gnviewSendLogMessage(`Schedule componentDidMount error: ${error.message}`, error, params);
                        });
                }
            }
            if (params.search_id) {
                this.props.gnviewGetSavedSearch(params.search_id).then((response) => {
                    const selectedSearchTitle = response.result?.title;
                    this.setState({ selectedSearchTitle });
                }).catch(() => {
                    // API throws an error if saved search does not exist or is not owned by user
                    this.setState({ savedSearchIsLoading: false });
                    this.props.history.push(ROUTES.SCHEDULES);
                });
            }
        }

        const username = this.props.userSettings?.username;
        if (username) {
            this.props.gnviewGetUserSubscriptions(username)
                .then(response => {
                    const subscriptions = response || [];
                    this.setState({
                        subscriptions,
                        subscriptionsLoading: false
                    });
                    const currentSubscription = subscriptions.find(s => s.station_id.toString() === this.state.selectedStation.id.toString());
                    this.setState({ alreadySubscribed: Boolean(currentSubscription), currentSubscription });
                })
                .catch(error => {
                    this.props.gnviewSendLogMessage(`gnviewGetUserSubscriptions error: ${error.message}`, "ERROR", error, { username });
                })
                .finally(() => {
                    this.setState({ subscriptionsLoading: false });
                });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.defaultGridStart !== this.props.defaultGridStart) {
            this.setState({ gridStartHour: getGridStartHour(this.props.defaultGridStart) });
        }
    }

    isOutsideDateRange = () => {
        const prevStartDate = getPrevDate(this.state.currentStartDate, this.state.isWeekly);
        const nextEndDate = getNextDate(this.state.currentEndDate, this.state.isWeekly);
        const prevIsDisabled = prevStartDate.isBefore(this.state.minDate.startOf('day'));
        const nextIsDisabled = nextEndDate.isAfter(this.state.maxDate.endOf('day'));
        this.setState({ prevIsDisabled, nextIsDisabled });
    }

    getCurrentDateText = () => {
        if (!this.state.currentStartDate || !this.state.currentEndDate) {
            return '';
        }
        if (!this.state.isWeekly) {
            return this.state.currentStartDate.format('MMMM D, YYYY');
        }
        return this.state.currentStartDate.format('MMMM D, YYYY') + ' - ' + this.state.currentEndDate.format('MMMM D, YYYY');
    }

    setURL = (start, end) => {
        if (start && end) {
            const params = {
                ...queryString.parse(this.props.location.search),
                start_date: start.format('YYYY-MM-DD'),
                end_date: end.format('YYYY-MM-DD')
            };
            const paramsString = queryString.stringify(params);
            this.props.history.push(`${ROUTES.SCHEDULES}/result?${paramsString}`);
        }
    }

    handleNavPrevious = () => {
        this.setState({alerts: {}})
        const start = getPrevDate(this.state.currentStartDate, this.state.isWeekly);
        const startISO = start.toISOString();
        const end = getPrevDate(this.state.currentEndDate, this.state.isWeekly);
        const endISO = end.toISOString();
        if (startISO && endISO) {
            const stationsCopy = [...this.state.stations];
            stationsCopy.forEach((station) => {
                station.tzStartDate = getPrevDate(station.tzStartDate, this.state.isWeekly);
                station.tzEndDate = getPrevDate(station.tzEndDate, this.state.isWeekly);
                station.utcStartDate = station.tzStartDate.toISOString();
                station.utcEndDate = station.tzEndDate.toISOString();
            });
            const headerDates = this.getHeaderDates(start, end);
            this.getLastUpdatedTime(stationsCopy, true, this.state.isWeekly);
            this.setURL(start, end);
            this.setState({ stations: stationsCopy, currentStartDate: start, currentEndDate: end, headerDates }, () => this.isOutsideDateRange());
        }
        if (this.props.isBasic) {
            if (!this.props.lookupCount || this.props.lookupCount <= this.props.lookupMax) {
                this.postLookup(this.props.email);
            }
        }
    }

    handleNavNext = () => {
        this.setState({alerts: {}})
        const start = getNextDate(this.state.currentStartDate, this.state.isWeekly);
        const startISO = start.toISOString();
        const end = getNextDate(this.state.currentEndDate, this.state.isWeekly);
        const endISO = end.toISOString();
        if (startISO && endISO) {
            const stationsCopy = [...this.state.stations];
            stationsCopy.forEach((station) => {
                station.tzStartDate = getNextDate(station.tzStartDate, this.state.isWeekly);
                station.tzEndDate = getNextDate(station.tzEndDate, this.state.isWeekly);
                station.utcStartDate = station.tzStartDate.toISOString();
                station.utcEndDate = station.tzEndDate.toISOString();
            });
            const headerDates = this.getHeaderDates(start, end);
            this.getLastUpdatedTime(stationsCopy, true, this.state.isWeekly);
            this.setURL(start, end);
            this.setState({ stations: stationsCopy, currentStartDate: start, currentEndDate: end, headerDates }, () => this.isOutsideDateRange());
        }
        if (this.props.isBasic) {
            if (!this.props.lookupCount || this.props.lookupCount <= this.props.lookupMax) {
                this.postLookup(this.props.email);
            }
        }
    }

    exportSchedule = () => {
        const params = queryString.parse(this.props.location.search);
        if (params.station_ids && params.start_date && params.end_date) {
            this.setState({ exportIsLoading: true });
            this.props.gnviewGetExportSchedule(params.station_ids, params.start_date, params.end_date, this.props.defaultTimezone).then((response) => {
                downloadExportFile(response.result, 'text/csv; charset=utf-8', formatExportFileName(response));
                this.setState({ exportIsLoading: false });
            }).catch((error) => {
                this.props.gnviewSendLogMessage(`gnviewGetExportSchedules error: ${error.message}`, error, { stationIds: params.station_ids, start_date: params.start_date, end_date: params.end_date });
                this.setState({ exportIsLoading: false });
            });
        }
    }

    // Multi-station weekly dropdown
    handleStationChange = (station) => {
        const stationsCopy = this.state.stations.map((stn) => ({
            ...stn,
            show: stn.id === station.id
        }));
        this.getLastUpdatedTime(stationsCopy, true, this.state.isWeekly);
        const currentSubscription = this.state.subscriptions?.find(s => s.station_id.toString() === station.id.toString());
        this.setState({ stations: stationsCopy, selectedStation: station, alreadySubscribed: Boolean(currentSubscription), currentSubscription });
    }

    handlePrevDailyStations = () => {
        const newStartIdx = (this.state.currentDailyStartIndex - DAILY_MAX_STATIONS <= 0) ? 0 : this.state.currentDailyStartIndex - DAILY_MAX_STATIONS;
        const newLastIdx = this.state.currentDailyStartIndex;
        const stationsCopy = this.state.stations.map((stn, idx) => ({
            ...stn,
            show: (idx >= newStartIdx) && (idx < newLastIdx)
        }));
        this.getLastUpdatedTime(stationsCopy, true, this.state.isWeekly);
        this.setState({ stations: stationsCopy, currentDailyStartIndex: newStartIdx });
    }

    handleNextDailyStations = () => {
        const newStartIdx = this.state.currentDailyStartIndex + DAILY_MAX_STATIONS;
        const newLastIdx = newStartIdx + DAILY_MAX_STATIONS;
        const stationsCopy = this.state.stations.map((stn, idx) => ({
            ...stn,
            show: (idx >= newStartIdx) && (idx < newLastIdx)
        }));
        this.getLastUpdatedTime(stationsCopy, true, this.state.isWeekly);
        this.setState({ stations: stationsCopy, currentDailyStartIndex: newStartIdx });
    }

    onToggleClick = (event) => {
        if (!this.state.isLoading && !this.state.subscriptionsLoading) {
            if (event.target.checked) {
                this.setState({ showSubscribeModal: event.target.checked });
            } else if (!event.target.checked) {
                this.setState({ showConfirmUnsubscribeModal: !event.target.checked})
            }
        }
    }

    shouldShowSubscribeModal = () => {
        return this.props.isChannelAlertsFFOn && !this.state.isLoading && !this.state.alreadySubscribed && this.state.showSubscribeModal
    }

    isSubscriptionToggleDisabled = () => {
        return this.state.subscriptions?.length >= SUBSCRIPTIONS_LIMIT
    }

    getToggleButtonTooltip = () => {
        if (this.isSubscriptionToggleDisabled()) {
            return SUBSCRIPTIONS_LIMIT_TOOLTIP;
        } else if (!this.state.alreadySubscribed) {
            return SUBSCRIPTIONS_TURN_ON_TOOLTIP;
        }

        return '';
    }

    handleUnsubscription = async () => {
        const subscriptionId = this.state.currentSubscription?.subscription_id;
        try {
            this.setState({ isUnsubscriptionInProgress: true })
            await this.props.deleteSubscription(subscriptionId);
            this.setState({ alreadySubscribed: false });
        } catch (error) {
            this.props.gnviewSendLogMessage(`deleteSubscription error: ${error.message}`, "ERROR", error, {subscriptionId});
            this.setState({ showNotification: true, notificationMessage: SUBSCRIBE_TO_ALERTS_LABELS.UNSUBSCRIPTION_ERROR })
        } finally {
            this.setState({ showConfirmUnsubscribeModal: false, isUnsubscriptionInProgress: false })
            gnviewGetAlertsCount(this.props.email, 'this-week').then((response) => {
                const count = {'this-week': response?.result?.alert_count}
                this.props.gnviewDataAlertsSetAlertsCounts(count);
            }).catch(error => {
                gnviewSendLogMessage(error)
            })
        }
    }

    handleCancelUnsubscription = () => {
        this.setState({ showConfirmUnsubscribeModal: false });
    }

    handleAfterSubscription = (newSubscription) => {
        const updatedSubscriptions = this.props.subscriptions ? this.props.subscriptions.map((sub) => {
            if (sub.subscription_id === this.state.currentSubscription?.subscription_id) {
                sub.distributionPlatforms = newSubscription.distributionPlatforms;
                sub.labels = newSubscription.labels;
                sub.source_of_channel = newSubscription.source_of_channel;
                sub.station_id = newSubscription.station_id;
                sub.station_name = newSubscription.station_name;
                sub.subscription_id = newSubscription.subscription_id;
                sub.station_image = newSubscription.station_image || '';
            }
            return sub;
        }) : [];
        this.props.gnviewDataAlertsSetSubscriptions(updatedSubscriptions);
        this.setState({ subscriptions: updatedSubscriptions, alreadySubscribed: true, showSubscribeModal: false, currentSubscription: newSubscription });
        gnviewGetAlertsCount(this.props.email, 'this-week').then((response) => {
            const count = {'this-week': response?.result?.alert_count}
            this.props.gnviewDataAlertsSetAlertsCounts(count);
        }).catch(error => {
            gnviewSendLogMessage(error)
        })
    }

    handleErrorSubscription = () => {
        this.setState({ showSubscribeModal: false, notificationMessage: SUBSCRIBE_TO_ALERTS_LABELS.SUBSCRIPTION_ERROR, showNotification: true })
    }

    handleShowLoadingIndicator = () => {
        this.setState({ isAlertsLoading: !this.state.isAlertsLoading })
    }

    getAlertsCount = () => {
        return Object.keys(this.state.alerts).reduce((prev, key) => prev + this.state.alerts[key], 0)
    }

    handleSendUpdateButton = () => {
        this.props.gnviewSetLastURLVisited(window.location);
        window.open(ROUTES.REPORT_AN_ISSUE, '_blank');
    }

    render() {
        const params = queryString.parse(this.props.location.search);
        const searchId = params['search_id'];
        const dateType = params['date_type'];
        const startDate = params['start_date'];
        const endDate = params['end_date'];
        const dateObj = { startDate, endDate }
        const savedItemBody = scheduleSavedItemBodyFrom(
            this.props.email,
            this.state.selectedSearchTitle,
            this.state.stations.map(s => s.id),
            dateType,
            startDate,
            endDate,
            dateObj
        );
        const formattedDate = getFormattedDate(
            dateType !== EXACT_DATE,
            dateType,
            this.state.currentStartDate?.format('MMMM D, YYYY'),
            this.state.currentEndDate?.format('MMMM D, YYYY'),
            this.state.currentStartDate,
            this.state.currentEndDate
        );
        return (this.props.isBasic && this.props.lookupCount > this.props.lookupMax) ? <Redirect to={ROUTES.LOOKUP_MAX} /> : (
            <ErrorBoundary>
                <div className="gnview-schedules-content">
                    <div className="schedule-top-bar">
                        <div className="schedule-top-section">
                            <div className="gnview-header-title">{SCHEDULE_REPORT}{this.state.stations.length > 1 ? ` (${this.state.stations.length} stations)` : ''}</div>
                            <div className="date-section">
                                <OverlayTrigger placement="bottom" delay={{ hide: 100 }} overlay={<Tooltip>Previous {this.state.isWeekly ? 'Week' : 'Day'}</Tooltip>}>
                                    <Button variant="icon-only" className="prev-button" onClick={this.handleNavPrevious} disabled={this.state.isLoading || this.state.prevIsDisabled}>
                                        <i className="fas fa-angles-left icon" />
                                    </Button>
                                </OverlayTrigger>
                                <span className="current-time">{this.getCurrentDateText()}</span>
                                <OverlayTrigger placement="bottom" delay={{ hide: 100 }} overlay={<Tooltip>Next {this.state.isWeekly ? 'Week' : 'Day'}</Tooltip>}>
                                    <Button variant="icon-only" className="next-button" onClick={this.handleNavNext} disabled={this.state.isLoading || this.state.nextIsDisabled}>
                                        <i className="fas fa-angles-right icon" />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            <ExportButton variant="primary" exportFunction={this.exportSchedule} type='.csv' isLoading={this.state.exportIsLoading} />
                            <MySavedItemsButton
                                body={savedItemBody}
                                searchId={searchId}
                                formattedDate={formattedDate}
                                selectedStations={this.state.stations}
                                validationCallback={() => false}
                            />
                        </div>
                        <div className='schedule-updates-in-real-time'>{SCHEDULE_UPDATES_REAL_TIME}</div>
                        <div className="schedule-day-header">
                            <div className="top-section">
                                {this.state.stations?.length > DAILY_MAX_STATIONS && !this.state.isWeekly && this.state.currentDailyStartIndex > 0 && <Button variant="light" className="prev-stations-button" onClick={this.handlePrevDailyStations}>
                                    <i className="fas fa-angle-left icon" />
                                </Button>}
                                <div className='left-section'>
                                    {this.state.stations?.length > 1 && this.state.isWeekly && <StationDropdown label="Current Station:" stations={this.state.stations} handleStationChange={this.handleStationChange} />}
                                    {this.state.stations?.length > 0 && this.props.isChannelAlertsFFOn && !this.state.subscriptionsLoading && <GNToggleButton
                                        onClick={this.onToggleClick}
                                        checked={Boolean(this.state.alreadySubscribed)}
                                        defaultChecked={Boolean(this.state.alreadySubscribed)}
                                        tooltipMsg={this.getToggleButtonTooltip()}
                                        isDisabled={this.isSubscriptionToggleDisabled() && !this.state.alreadySubscribed} className={'subscribe-toggle-button'}
                                    />}
                                    {this.state.stations?.length > 0 && this.props.isChannelAlertsFFOn && this.state.alreadySubscribed && Object.keys(this.state.alerts).length > 0 &&
                                        <>
                                            <AlertsSummaryButton
                                                alerts={this.state.alerts}
                                                alertsCount={this.getAlertsCount()}
                                            />
                                            {this.state.isAlertsLoading && <FontAwesomeIcon icon='spinner' />}
                                        </>}
                                    <span className='send-update-button'>
                                        <Link target="_blank" rel="noreferrer" onClick={this.handleSendUpdateButton}>
                                            <Button>
                                            Send Update
                                            </Button>
                                        </Link>
                                    </span>
                                </div>
                                {this.state.stations?.length > 0 && <Row className={ClassNames("station-header-container", { 'small-header': !this.state.isWeekly && this.state.stations.length > 1 })}>
                                    {this.state.stations?.length > 0 && <div className="time-axis-spacer" />}
                                    {this.state.stations.map((station, index) => {
                                        if (station.show) {
                                            return <Col key={index}><StationHeader station={station} /></Col>;
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Row>}
                                {this.state.stations?.length > DAILY_MAX_STATIONS && !this.state.isWeekly && (this.state.currentDailyStartIndex + DAILY_MAX_STATIONS) < this.state.stations.length && <Button variant="light" className="next-stations-button" onClick={this.handleNextDailyStations}>
                                    <i className="fas fa-angle-right icon" />
                                </Button>}
                            </div>
                            {/* Daily Schedule Report Last Updated */}
                            {this.state.stations?.length > 0 && !this.state.isWeekly && (
                                <Row className='station-header-last-updated'>
                                    {Object.keys(this.state.dailyLastUpdatedTime)?.length > 0 && (
                                        <div className='station-header-last-updated__label'>
                                            {SCHEDULE_LAST_UPDATED}
                                        </div>
                                    )}
                                    {this.state.stations.map((station, index) => {
                                        if (station.show) {
                                            return (
                                                <Col className='station-header-last-updated__col' key={index}>
                                                    {Object.keys(this.state.dailyLastUpdatedTime)?.length > 0 && (
                                                        <span
                                                            className='station-header-last-updated__col-datetime'
                                                        >
                                                            {this.state.dailyLastUpdatedTime[station.id]}
                                                        </span>
                                                    )}
                                                </Col>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </Row>
                            )}
                            {!this.state.isLoading && this.state.isWeekly && (
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='axis-col'></th>
                                            {this.state.headerDates.map((date, index) => (
                                                <th className='day-header' key={index}>
                                                    <div className='day-name'>{date.dayName}</div>
                                                    <div className='day-of-month'>{date.dayOfMonth}</div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    {/* Weekly Schedule Report Last Updated */}
                                    {this.state.lastUpdatedTime?.length > 0 && (
                                        <tbody className='last-updated-header'>
                                            <tr>
                                                <th className='last-updated-header__label'>{SCHEDULE_LAST_UPDATED}</th>
                                                {this.state.lastUpdatedTime.map((lastUpdatedTime, index) => {
                                                    return (
                                                        <th key={index} className='last-updated-header__datetime'>
                                                            <div>{lastUpdatedTime}</div>
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            )}
                        </div>
                    </div>
                    <div className="calendar-section">
                        {!this.state.isLoading && <ScheduleTimeAxis gridStartHour={this.state.gridStartHour} />}
                        <Row>
                            {this.state.stations?.length > 0 && !this.state.isLoading && this.state.stations.map((station, index) => {
                                if (station.show) {
                                    return (
                                        <Col key={index} >
                                            <ScheduleCalendar
                                                isWeekly={this.state.isWeekly}
                                                currentStartDate={this.state.currentStartDate.format('YYYY-MM-DD')}
                                                station={station}
                                                utcStartDate={station.utcStartDate}
                                                isStationSubscribed={this.state.alreadySubscribed}
                                                setAlerts={(alerts) => this.setAlerts(this.state.alerts, alerts)}
                                                handleShowLoadingIndicator={this.handleShowLoadingIndicator}
                                                utcEndDate={station.utcEndDate} />
                                        </Col>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </Row>
                    </div>
                    <div className='legend-footer-container'>
                        <ScheduleLegend />
                    </div>
                </div>
                {this.shouldShowSubscribeModal() &&
                    <SubscribeModal
                        show={this.state.showSubscribeModal}
                        handleModal={() => this.setState({ showSubscribeModal: !this.state.showSubscribeModal })}
                        selectedStation={this.state.selectedStation}
                        handleAfterSubscription={this.handleAfterSubscription}
                        handleErrorSubscription={this.handleErrorSubscription}
                    />}
                {this.state.showConfirmUnsubscribeModal && <GNConfirmationModal
                    cancelButtonText={UNSUBSCRIBE_MODAL.CANCEL}
                    message={UNSUBSCRIBE_MODAL.TEXT}
                    show={this.state.showConfirmUnsubscribeModal}
                    cancelButtonHandler={this.handleCancelUnsubscription}
                    submitButtonHandler={this.handleUnsubscription}
                    submitButtonText={UNSUBSCRIBE_MODAL.UNSUBSCRIBE}
                    title={UNSUBSCRIBE_MODAL.TITLE}
                />}
                {this.props.isChannelAlertsFFOn && !this.state.isLoading && this.state.showNotification &&
                    <GNNotification
                        show={this.state.showNotification}
                        milliseconds={5000}
                        success={false}
                        handleShow={(show) => this.setState({ showNotification: show})}
                        message={this.state.notificationMessage} />}
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        defaultGridStart: gnviewSelUserSettings(state)?.schedule_grid_start_type,
        defaultTimezone: gnviewSelUserSettings(state)?.timezone,
        email: gvauthSelEmail(state),
        isBasic: gvauthIsBasic(state),
        isChannelAlertsFFOn: gvauthHasChannelAlerts(state),
        lookupCount: gvauthSelLookupCount(state),
        lookupMax: gvauthSelLookupMax(state),
        userSettings: gnviewSelUserSettings(state)
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        deleteSubscription,
        gnviewDataAlertsSetSubscriptions,
        gnviewGetStation,
        gnviewGetExportSchedule,
        gnviewGetUserSubscriptions,
        gnviewSendLogMessage,
        gnviewGetSavedSearch,
        gnviewGetSchedule,
        gnviewLookupCountUpdate,
        gnviewPostLookup,
        gnviewSetLastURLVisited,
        gnviewDataAlertsSetAlertsCounts
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleResult);

ScheduleResult.propTypes = {
    defaultTimezone: PropTypes.string,
    defaultGridStart: PropTypes.string,
    email: PropTypes.string,
    gnviewGetExportSchedule: PropTypes.func,
    gnviewDataAlertsSetSubscriptions: PropTypes.func,
    gnviewGetStation: PropTypes.func.isRequired,
    deleteSubscription: PropTypes.func.isRequired,
    gnviewGetUserSubscriptions: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func,
    gnviewGetSavedSearch: PropTypes.func,
    gnviewGetSchedule: PropTypes.func,
    gnviewLookupCountUpdate: PropTypes.func,
    gnviewPostLookup: PropTypes.func,
    gnviewSetLastURLVisited: PropTypes.func,
    history: ReactRouterPropTypes.history,
    isBasic: PropTypes.bool,
    isChannelAlertsFFOn: PropTypes.bool,
    location: ReactRouterPropTypes.location.isRequired,
    lookupCount: PropTypes.number,
    lookupMax: PropTypes.number,
    userSettings: PropTypes.any,
    subscriptions: PropTypes.array,
    gnviewDataAlertsSetAlertsCounts: PropTypes.func
};