import PropTypes from 'prop-types'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gnviewGetProgramDetailsUnrestricted } from '../../../actions/GNViewActions';
import { gnviewSendLogMessage } from '../../../services/GeneralService';
import { getImageURL } from '../../../utils/ImageUtils';
import {
    getProgramTitle,
    getProgramTitleAltText,
    getProgramColor,
    getProgramImageVertical,
    getProgAlerts
} from '../../../utils/ProgramUtils';
import ErrorBoundary from '../../../components/common/errorBoundary/ErrorBoundary';
import ClassNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export class ScheduleEventTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programData: null
        };
    }

    componentDidMount() {
        const tmsId = this.props.event.extendedProps.tmsid;
        this.props.gnviewGetProgramDetailsUnrestricted(tmsId).then((programResponse) => {
            const program = programResponse.result;
            if (program) {
                const programData = {
                    ...program,
                    progTitle: getProgramTitle(program, true),
                    progTitleAlt: getProgramTitleAltText(program),
                    progColor: getProgramColor(program.type) || 'light-gray',
                    progImageURL: getImageURL(getProgramImageVertical(program)),
                    progAlerts: getProgAlerts(program) || []
                };
                this.setState({programData});
                if (this.props.setAlerts) {
                    this.props.setAlerts(programData.progAlerts)
                }
                if (this.props.handleShowLoadingIndicator) {
                    this.props.handleShowLoadingIndicator()
                }
            }
        }).catch((error) => {
            this.props.gnviewSendLogMessage(`ScheduleEventTile gnviewGetProgramDetailsUnrestricted error: ${error.message}`, error, {tmsId});
        });
    }

    componentWillUnmount() {
        this.props.handleShowLoadingIndicator();
    }

    shouldShowAlerts() {
        return this.props.isStationSubscribed && this.state.programData.progAlerts?.length > 0
    }

    getTooltipStyles() {
        return ({paddingLeft: '10px', paddingTop: '10px', paddingRight: '10px', textAlign: 'left'});
    }

    generateAlertsTooltipData() {
        if (!this.shouldShowAlerts()) {
            return null;
        }
        return (
            <ul style={this.getTooltipStyles()}>
                {this.state.programData.progAlerts.map((alert, index) => (<li key={index}>
                    {alert.trim()}
                </li>))}
            </ul>
        )
    }

    render() {
        return (
            <ErrorBoundary>
                {this.state.programData &&
                <OverlayTrigger overlay={<Tooltip id="alerts-tooltip">{this.generateAlertsTooltipData()}</Tooltip>} placement="bottom" trigger={this.shouldShowAlerts() ? ['hover', 'focus'] : null}>
                    <div className={ClassNames(`event-tile ${this.state.programData?.progColor}`, {'alert': this.shouldShowAlerts()})} data-testid='event-tile'>
                        <div className="top-section">
                            <div className="title">{this.state.programData?.progTitle}</div>
                            <div className="time">{this.props.event.extendedProps.startTimeFormatted} - {this.props.event.extendedProps.endTimeFormatted}</div>
                        </div>
                        <div className={ClassNames("bottom-section", {'daily': !this.props.isWeekly})}>
                            <div className="rating">{this.props.event.extendedProps.progInfo.rating}</div>
                            {this.props.renderProgQuals(this.props.event.extendedProps.progInfo.quals)}
                        </div>
                    </div>
                </OverlayTrigger>}
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = () => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        gnviewGetProgramDetailsUnrestricted,
        gnviewSendLogMessage
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEventTile);

ScheduleEventTile.propTypes = {
    event: PropTypes.object.isRequired,
    gnviewGetProgramDetailsUnrestricted: PropTypes.func.isRequired,
    gnviewSendLogMessage: PropTypes.func,
    handleShowLoadingIndicator: PropTypes.func,
    isWeekly: PropTypes.bool,
    isStationSubscribed: PropTypes.bool,
    renderProgQuals: PropTypes.func.isRequired,
    setAlerts: PropTypes.func
}