import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ADVANCED_PROGRAM_SEARCH_RESULTS_LIMIT,
    ADVANCED_PROGRAM_SEARCH_TYPES
} from '../../../constants/AdvancedSearch';
import {
    ADVANCED_PROGRAM_NO_DATA_MESSAGE,
    ADVANCED_PROGRAM_NO_DATA_MESSAGE_HEADER,
    ADVANCED_SEARCH_RESULTS_COLUMN_HEADERS
} from '../../../constants/AdvancedSearch';
import GNServerTable from '../../../components/common/gnServerTable/GNServerTable';
import './AdvancedSearchResultsView.scss';
import AdvancedSearchHeader from './AdvancedSearchHeader';
import {
    gnviewAdvancedSearchProgramIdentifiers,
    gnviewAdvancedSearchUpdateResultTableReset
} from '../../../actions/GNViewActions';
import {
    gnviewSelAdvancedSearchProgramAttributesFields,
    gnviewSelAdvancedSearchRootIdFields,
    gnviewSelAdvancedSearchType,
    gnviewSelAdvancedSearchResultTableReset,
    gnviewSelAdvancedSearchSeriesIdFields
} from '../../../reducers/AdvancedSearchReducer';
import NoDataMessage from '../../../components/common/noDataMessage/NoDataMessage';
import GNNotification from '../../../components/common/gnNotification/GNNotification';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { gvauthSelFeatureFlags } from '../../../reducers/GNVAuthReducer';

export const AdvancedSearchResultsView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchType = useSelector(gnviewSelAdvancedSearchType);
    const searchProgramAttributesFields = useSelector(gnviewSelAdvancedSearchProgramAttributesFields);
    const searchRootIdFields = useSelector(gnviewSelAdvancedSearchRootIdFields);
    const searchSeriesIdFields = useSelector(gnviewSelAdvancedSearchSeriesIdFields);
    const shouldResetTable = useSelector(gnviewSelAdvancedSearchResultTableReset);
    const [copiedNotification, setCopiedNotification] = useState(false);
    const featureFlags = useSelector(gvauthSelFeatureFlags);
    const userSelectedMappingFFEnabled = featureFlags?.includes('user-selected-mapping');

    const search = location.search

    useEffect(() => {
        if (shouldResetTable) {
            // Reset this value back to false
            dispatch(gnviewAdvancedSearchUpdateResultTableReset(false));
        }
    }, [dispatch, shouldResetTable]);

    const fetchResultsData = useCallback((pageIndex, tableSortBy) => {
        const formattedAdvancedSearchFields = {
            ...(searchType === ADVANCED_PROGRAM_SEARCH_TYPES.rootId && searchRootIdFields),
            ...(searchType === ADVANCED_PROGRAM_SEARCH_TYPES.seriesId && searchSeriesIdFields),
            ...(searchType === ADVANCED_PROGRAM_SEARCH_TYPES.programAttributes && searchProgramAttributesFields)
        };
        const from = pageIndex * ADVANCED_PROGRAM_SEARCH_RESULTS_LIMIT;
        const sortField = tableSortBy[0]?.id || null;
        const sortOrder = tableSortBy[0]?.desc ? 'desc' : 'asc';

        if (sortField && sortOrder) {
            return gnviewAdvancedSearchProgramIdentifiers(
                from,
                ADVANCED_PROGRAM_SEARCH_RESULTS_LIMIT,
                formattedAdvancedSearchFields,
                sortField,
                sortOrder
            );
        } else {
            return gnviewAdvancedSearchProgramIdentifiers(
                from,
                ADVANCED_PROGRAM_SEARCH_RESULTS_LIMIT,
                formattedAdvancedSearchFields
            );
        }
    }, [searchType, searchRootIdFields, searchSeriesIdFields, searchProgramAttributesFields]);

    const onClickCopyTmsId = async (tmsId) => {
        await navigator.clipboard.writeText(tmsId);
        setCopiedNotification(true);
    }

    return (
        <div className='gnview-advanced-search-result-content'>
            <div className='gnview-advanced-search-search-view'>
                <AdvancedSearchHeader isResultsView={true} />
            </div>
            <div className='gnview-advanced-search-results-view'>
                <div className='results-body-container'>
                    {!shouldResetTable && (
                        <GNServerTable
                            className='advanced-search-results-table'
                            columns={ADVANCED_SEARCH_RESULTS_COLUMN_HEADERS(search.length && userSelectedMappingFFEnabled, onClickCopyTmsId)}
                            fetchData={fetchResultsData}
                            noDataMessage={<NoDataMessage isStatusTable={false} header={ADVANCED_PROGRAM_NO_DATA_MESSAGE_HEADER} message={ADVANCED_PROGRAM_NO_DATA_MESSAGE} />}
                        />
                    )}
                </div>
            </div>
            {copiedNotification && userSelectedMappingFFEnabled && (
                <GNNotification
                    handleShow={setCopiedNotification}
                    message={<span>TMS ID is copied to your clipboard and ready to paste into the <b><q>Self-linked TMS ID</q></b> field.</span>}
                    milliseconds={5000}
                    show={copiedNotification}
                    success={true}
                />
            )}
        </div>
    );
};

export default AdvancedSearchResultsView;
