import { SUBSCRIBE_TO_CHANNEL_FORM_FIELDS, SUBSCRIBE_TO_ALERTS_LABELS, SOURCE_TYPES_MAPPING } from './Station';
import { ROUTES } from '../config/Routes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";
import GNBadge from '../components/common/gnBadge/GNBadge';
import React from 'react';
import moment from 'moment';

export const DATA_ALERTS = 'Data Alerts'
export const DATA_ALERTS_MAX_CHANNELS_MESSAGE = 'Maximum of 10 channels can be tracked. Unsubscribe from an existing channel to add a new one.'
export const DATA_ALERTS_TYPES = {
    ALERTS: "Alerts",
    SUBSCRIBED_CHANNELS: "Subscribed Channels"
};
export const DATA_ALERT_ERROR_MESSAGES = {
    UNABLE_TO_CHECK: 'Unable to check alert. Please try again.',
    UNABLE_TO_UNCHECK: 'Unable to uncheck alert. Please try again.',
    NO_DATA_ALERTS: 'No Data Alerts at the moment. Clear your filters or check again tomorrow for alerts.'
};
const utcTime = moment.utc('16:00:00', 'HH')
const localTime = utcTime.local()
const zoneName = moment.tz.guess();

export const NO_SUBSCRIPTIONS = "You are not subscribed to any channels."

export const DATA_ALERTS_UPDATED = `Updated every morning ${localTime.format('hh:mm A')} ${moment.tz(zoneName).zoneAbbr()}.\nView Channel Schedule for realtime status`
export const DATA_ALERTS_TABS = {
    [DATA_ALERTS_TYPES.ALERTS]: {name: DATA_ALERTS_TYPES.ALERTS, icon: 'clock-rotate-left'},
    [DATA_ALERTS_TYPES.SUBSCRIBED_CHANNELS]: {name: DATA_ALERTS_TYPES.SUBSCRIBED_CHANNELS, icon: 'bookmark'}
};

export const DATA_ALERTS_WINDOWS = [{week: 'This Week', rangeValue: "this-week", notificationCount: 0}, {week: 'Next Week', rangeValue: "next-week", notificationCount: 0}, {week: '3 Weeks Out', rangeValue: "three-weeks-out", notificationCount: 0}]

export const DATA_ALERTS_WINDOW_TABS = {
    [DATA_ALERTS_WINDOWS.THIS_WEEK]: {name: DATA_ALERTS.THIS_WEEK},
    [DATA_ALERTS_WINDOWS.NEXT_WEEK]: {name: DATA_ALERTS.NEXT_WEEK},
    [DATA_ALERTS_WINDOWS.THREE_WEEKS_OUT]: {name: DATA_ALERTS.THREE_WEEKS_OUT}
}

export const getUpdateValues = (currentSubscription) => ({
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.SOURCE_TYPE]: currentSubscription?.source_of_channel || SOURCE_TYPES_MAPPING[SUBSCRIBE_TO_ALERTS_LABELS.ORIGINATOR],
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.DISTRIBUTION_PLATFORMS]: currentSubscription?.distributionPlatforms.map((distributionPlatformObj) => distributionPlatformObj.distributionPlatform) || [],
    [SUBSCRIBE_TO_CHANNEL_FORM_FIELDS.LABELS]: currentSubscription?.labels.map((labelsObj) => labelsObj.label) || []
});

/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
export const DATA_ALERTS_HEADERS = [
    {
        id: "unreviewedCheckmarkIdx",
        accessor: "id",
        disableSortBy: true,
        width: 60
    },
    {
        accessor: "scheduleDate",
        Header: "First Air Date",
        Cell: ({ value }) => moment(value).local().format("MM/DD/YY")

    },
    {
        accessor: "details",
        Header: "Program Title",
        width: 250,
        Cell: ({ value }) => <OverlayTrigger
            overlay={<Tooltip id="program-title-tooltip">{value.title }</Tooltip>}
            trigger={(value.title.length > 36) ? ['hover'] : null}
        >
            <span
                className='program-title'
            >
                <Link target="_blank" rel="noreferrer" to={`${ROUTES.PROGRAM_DETAILS}/${value.program}`}>
                    {value?.title}
                </Link>
            </span>
        </OverlayTrigger>
    },
    {
        accessor: "details.program",
        Header: "TMSID",
        width: 200,
        Cell: ({ value }) => <span>
            {value}

        </span>
    },
    {
        accessor: "alertCategory",
        Header: "Data Category",
        width: 200,
        Cell: ({ value }) => <GNBadge subtype={value} color={'color-data-category'} />
    },
    {
        accessor: "description",
        Header: "Alert",
        width: 500
    }
];

export const REVIEWED_DATA_ALERTS_NO_HEADERS = [
    {
        id: "reviewedCheckmarkIdx",
        accessor: "id",
        hideHeader: true,
        width: 60,
        disableSortBy: true
    },
    {
        accessor: "scheduleDate",
        hideHeader: true,
        Cell: ({ value }) => moment(value).format("MM/DD/YY")
    },
    {
        accessor: "details",
        innerWidth: 15000,
        hideHeader: true,
        width: 250,
        Cell: ({ value }) =>
            <OverlayTrigger
                overlay={<Tooltip id="program-title-tooltip">{value.title }</Tooltip>}
                trigger={(value.title > 36) ? ['hover'] : null}
            >
                <span
                    className='program-title'
                >
                    <Link target="_blank" rel="noreferrer" to={`${ROUTES.PROGRAM_DETAILS}/${value.program}`}>
                        {value?.title}
                    </Link>
                </span>
            </OverlayTrigger>
    },
    {
        accessor: "details.program",
        hideHeader: true,
        width: 200,
        Cell: ({ value }) => <span>
            {value}

        </span>
    },
    {
        accessor: "alertCategory",
        hideHeader: true,
        width: 200,
        Cell: ({ value }) => <GNBadge subtype={value} color={'color-data-category'} />


    },
    {
        accessor: "description",
        width: 500,
        hideHeader: true
    }
];
/* eslint-enable react/display-name */
/* eslint-enable react/prop-types */
export const DATA_CATEGORIES_FILTERS = ['Image', 'Program TBA', 'Description', 'Series Metadata'];

export const SELECTED_FILTERS_INITIAL_VALUES = {
    CHANNELS: {
        name: 'All Channels',
        value: ''
    },
    DATA_CATEGORIES: {
        name: 'All Data Categories',
        value: ''
    },
    DATE: {
        name: '',
        value: ''
    },
    LABELS: {
        name: 'All Labels',
        value: ''
    }
};